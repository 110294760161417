import EventEmitter from 'events';

import { AuthError, NotFoundError } from 'CommonExceptions';
import Api from 'Api/Api';
import ApiContext from 'Api/ApiContext';
import { ApiErrorResult } from 'Api/ApiErrors';

import * as C from './ConfConstants';
import BroadcastController from './BroadcastController';
import BridgeSettingsController from './BridgeSettingsController';
import TaskScheduler from './TaskScheduler';
import CallCommand from './CallCommand';
import ConfCommand from './ConfCommand';
import ChatController from './ChatController';
import StoredIdentity from './StoredIdentity';

import s from './strings';

// the following are all in seconds
const POLL_DELAY = 10;
const DATA_SESSION_EXPIRES = POLL_DELAY * 3;

const POLL_CANCEL_ID = 'ConfControllerPoll';

const DEFAULT_CONF_SETTINGS = {
  confMode: 'conversation',
  entryChimes: 'chime',
  exitChimes: 'chime',
  locked: 0,
  recordCalls: 0,
};

export default class ConfController extends EventEmitter {
  static ACTIVITY_DISCONNECTED = 0;
  static ACTIVITY_HOLD = 1;
  static ACTIVITY_PENDING = 2;
  static ACTIVITY_5 = 3;
  static ACTIVITY_2 = 4;
  static ACTIVITY_0 = 5;

  constructor(config) {
    super();

    this._apiContext = Api.defaultContext;

    this._config = config;
    this._notesFields = config.notesFields;
    this._customColumns = config.customColumns;
    this._enableOperatorWebCall = config.enableOperatorWebCall;

    this._initPollerState();

    this._tcsState = null;
    this._permSubConfs = [];
    this._subConfs = [];

    this._infoRecordingCurrent = [];
    this._allCalls = [];
    this._callCountTotalActive = 0;
    this._callCountTotalDisconnected = 0;
    this._callCountMainConf = 0;
    this._callCountMainConfHands = 0;
    this._callCountCurrent = 0;
    this._handRaisingSelected = null;
    this._handRaisingPrevSelectedCallID = null;
    this._activeBroadcast = null;
    this._clientCallID = null;

    this._callObservers = new Map();

    this._setCallProps = {
      nameTranscribePosition: false,
      userID: false,
      handRaisedIndexDisplay: false,
    };
    this._setCallProp_test = {
      handRaisedIndexDisplay: val => val !== null,
    };
    this._totals = {};

    config.colConfig.forEach(col => {
      const { type, prop, sum } = col;
      if (type === 'custom') {
        this._setCallProps[prop] = false;
        if (sum)
          this._totals[prop] = 0;
      }
    });

    this._subConfID = null;

    this._poller = new TaskScheduler({
      task: this._sendPollRequest.bind(this),
    });
    this._poller.on('retryTimer', () => this.emit('retryTimer'));

    if (config.enableChat) {
      this.chatController = new ChatController();

      this.chatController.on('nameChange', e => {
        const { name } = e;
        StoredIdentity.name = name;
        this._identity.name = name;
        this.chatController.updateName(name);

        this._poller.scheduleNext(0, true);
      });
    }

    this.broadcastController = new BroadcastController();
    this.broadcastController.on('pollRequested', () => this.pollIfInactive());

    this.bridgeSettingsController = new BridgeSettingsController(config.settingsController);

    this.setDataInactive();

    setInterval(() => this._tick(), 1000);
  }

  _setBridgeData(bridgeData) {
    this._bridgeData = bridgeData;
    this._bridgeConfSettings = getConfSettings(this._bridgeData);

    this._permSubConfs = (this._bridgeData.permSubConfs || []).map(_ => createIdleSubConf(_.name));

    this._onUpdate();
  }

  _onUpdate() {
    if (!this._bridgeData)
      throw new Error('_onUpdate cannot happen without _setBridgeData being called first');

    const {
      writable,
    } = this;
    this.broadcastController.update({
      writable,
      activeBroadcast: this._activeBroadcast,
    });

    // setup subConfs
    this._subConfs = [
      ...this._permSubConfs,
    ];

    if (this._tcsState) {
      this._tcsState.subConfs.forEach(subConf => {
        const idx = this._subConfs.findIndex(_ => _.name === subConf.name);
        if (idx >= 0) {
          // replace placeholder subConf with actual one
          this._subConfs[idx] = subConf;
        } else {
          // add subConf
          this._subConfs.push(subConf);
        }
      });
    }

    if (this._subConfID && !this._subConfs.find(_ => _.name === this._subConfID)) {
      // add a placeholder if subConfID is set but that subConf does not exist
      this._subConfs.push(createIdleSubConf(this._subConfID));
    }

    const confState = this._subConfID === null
      ? this._tcsState && this._tcsState.mainConfState
      : this._subConfs.find(cur => cur.name === this._subConfID).confState;

    const bridgeActive = this._bridgeData.activeFlag;

    if (bridgeActive && confState) {
      const calls = [];
      this._allCalls.forEach(cur => {
        let add = false;
        if (cur.subConf !== undefined) {
          // current call is in a subconf, it has to match the current
          // subConfID
          add = cur.subConf === this._subConfID;
        } else {
          // current call is in main conf, filter it if the view is for
          // a subconference
          add = this._subConfID === null;
        }

        if (add) {
          calls.push(cur);
        }
      });

      const { handRaisingSelected } = this;
      const selectedIndex = handRaisingSelected && handRaisingSelected.call.handRaisedIndexSorted;
      calls.forEach(call => {
        const { handRaisedIndexSorted } = call;
        if (handRaisedIndexSorted && handRaisedIndexSorted === selectedIndex) {
          call.handRaisedIndexDisplay = 0;
        } else if (handRaisedIndexSorted) {
          call.handRaisedIndexDisplay = selectedIndex && handRaisedIndexSorted > selectedIndex
            ? handRaisedIndexSorted - 1
            : handRaisedIndexSorted;
        } else {
          call.handRaisedIndexDisplay = null;
        }
      });

      this.setData({
        confState,
        calls,
      });
    } else {
      this.setDataInactive();
    }
  }

  _initPollerState() {
    this._curVersion = 0;
    this._curCdrID = null;
  }

  _sendPollRequest(immediate = false) {
    // don't send requests when not logged in
    if (!this._identity)
      return;

    const delay = this._tcsState && !immediate
      ? POLL_DELAY
      : 0;

    const params = {
      delay,
      version: this._curVersion,
    };

    const { timezone } = this;
    if (timezone) {
      params.timezone = timezone;
    }

    if (this._identity.name) {
      params.dataSession = {
        expires: DATA_SESSION_EXPIRES,
        data: {
          name: this._identity.name,
        },
      };
    }

    Api.get('LCM', 'getConferenceInfo', params, { cancelID: POLL_CANCEL_ID })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          const { code } = err;
          if (code === 'ERR_API_CONFERENCE_NOT_FOUND') {
            return null;
          }
        }

        throw err;
      })
      .then(result => {
        this._prevRequestError = false;

        let conference = null;
        if (result) {
          conference = result.conference;

          var tcsVersion = conference.version;
          if (this._curCdrID !== conference.cdrID || tcsVersion > this._curVersion)
            this._curVersion = tcsVersion;

          this._curCdrID = conference.cdrID;
        } else {
          this._initPollerState();
        }

        this._startPollResponseTimer(conference);

        this._poller.scheduleNext(result ? 0 : POLL_DELAY * 1000);
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        if (this._prevRequestError) {
          this._initPollerState();

          this._startPollResponseTimer();

          this._poller.scheduleFailed();
        } else {
          this._prevRequestError = true;
          this._poller.scheduleNext(0, true);
        }
      });
  }

  _stopPollResponseTimer() {
    if (this._pollResponseTimer) {
      clearTimeout(this._pollResponseTimer);
    }
    this._pollResponseTimer = null;
  }

  _startPollResponseTimer(result = null) {
    this._stopPollResponseTimer();

    this._pollResponseTimer = setTimeout(() => {
      this._pollResponseTimer = null;
      this._onPollerUpdate(result);
    }, 0);
  }

  _onPollerUpdate(result = null) {
    if (!this._bridgeData)
      throw new Error('_onPollerUpdate cannot happen without _setBridgeData being called first');

    this._tcsState = null;
    this._infoRecordingCurrent = [];
    this._allCalls = [];
    this._callCountTotalActive = 0;
    this._callCountTotalDisconnected = 0;
    this._callCountMainConf = 0;
    this._callCountMainConfHands = 0;
    this._handRaisingSelected = null;
    this._handRaisingPrevSelectedCallID = null;
    this._activeBroadcast = null;
    this._clientCallID = null;

    const callMap = new Map();

    if (result) {
      const connectedCalls = [];

      this._tcsState = {
        mainConfState: createConfState(result),

        subConfs: (result.subConfs && result.subConfs.subConf || []).map(({
          name,
          callCount,
          handRaisedCount,

          status,
          startedDateDisplay,
          duration,
        }) => ({
          name,
          callCount,
          handRaisedCount,

          confState: {
            status,
            startedDateDisplay,
            duration,

            nameTranscribeCount: 0,
            dataPerm: null,
            waitingForHost: false,
          },
        })),

        settings: getConfSettings(result),

        connectedCall: null,
      };

      if (result.infoRecordingTags) {
        this._infoRecordingCurrent = this._config.infoRecordingConfig
          .filter(item => result.infoRecordingTags.includes(item.tag));
      }

      this._callCountTotalDisconnected = result.totalParticipants - result.currentParticipants;
      if (result.handRaisedCount) {
        this._callCountMainConfHands = result.handRaisedCount;
      }

      const {
        selectedCall,
        prevSelectedCall,
      } = result;
      const selectedCallID = selectedCall && selectedCall.callID || null;
      const prevSelectedCallID = prevSelectedCall && prevSelectedCall.callID || null;
      let prevSelectedFound = false;

      this._allCalls = result.calls && result.calls.call || [];
      this._allCalls.forEach(call => {
        this._setCallFields(call);
        call.isHandSelected = call.callID === selectedCallID;

        if (!call.disconnected) {
          this._callCountTotalActive++;

          if (!call.subConf) {
            this._callCountMainConf++;
          }
        }

        if (call.isHandSelected) {
          this._handRaisingSelected = {
            call,
            origMuted: parseInt(selectedCall.muted),
          };
        }

        if (!call.disconnected && call.callID === prevSelectedCallID) {
          prevSelectedFound = true;
        }

        if (!this._activeBroadcast && call.isBroadcast) {
          const {
            callID,
            name: description,
            dataPerm: {
              broadcast: {
                broadcastID = null,
                duration,
              },
            },
            stream,
            inGain,
          } = call;

          const durationMS = parseInt(duration, 10);
          const positionMS = parseInt(stream.state.position, 10);

          this._activeBroadcast = {
            broadcastID,
            callID,
            description,
            duration: Math.floor(durationMS / 1000),
            position: Math.floor(positionMS / 1000),
            isPlaying: !!stream.state.read,
            inGain,
          };
        }

        if (call.isClientCall && !call.disconnected) {
          this._clientCallID = call.callID;
        }

        if (call.connectedToCall) {
          connectedCalls.push(call);
        }

        callMap.set(call.participantID, call);
      });

      if (this._clientCallID) {
        const dstCall = connectedCalls.find(call => call.connectedToCall.callID === this._clientCallID);
        if (dstCall) {
          this._tcsState.connectedCall = {
            callerID: dstCall.callerNameCallerID,
          };
        }
      }

      if (prevSelectedFound) {
        this._handRaisingPrevSelectedCallID = prevSelectedCallID;
      }
    }

    this._callObservers.forEach((observerList, participantID) => {
      const call = callMap.get(participantID);
      observerList.forEach(observer => observer.update(call));
      if (!call) {
        this._callObservers.delete(participantID);
      }
    });

    if (this.chatController) {
      const chats = result && result.chats || [];

      this.chatController.update({
        isActive: !!result,
        chats,
      });
    }

    this._onUpdate();
  }

  setDataInactive() {
    this.setData({
      confState: null,
      calls: [],
    });
  }

  setData({ confState, calls }) {
    const bridgeActive = this._bridgeData
      ? this._bridgeData.activeFlag
      : true;

    let state = 'pending';
    if (bridgeActive) {
      if (confState) {
        state = confState.status === 'idle'
          ? 'pending'
          : 'active';
      } else if (this._poller.isRetrying) {
        state = 'error';
      }
    } else {
      state = 'inactive';
    }

    this._scope = this._apiContext.getScope();
    this._state = state;

    this._confState = confState;

    if (this._tcsState) {
      this._settings = this._tcsState.settings;
    } else if (this._bridgeConfSettings) {
      this._settings = this._bridgeConfSettings;
    } else {
      this._settings = DEFAULT_CONF_SETTINGS;
    }

    // reset time offset
    this._localTimeOffset = 0;

    if (!this.isConfActive) {
      this._lastUpdateTime = null;
      this._lastDuration = null;
      this._namePlayingParticipantID = null;
    } else {
      const d = new Date();
      this._lastUpdateTime = d.getTime();
    }

    this._callCountCurrent = 0;

    for (let prop in this._setCallProps)
      this._setCallProps[prop] = false;
    for (let prop in this._totals)
      this._totals[prop] = 0;

    calls.forEach(call => {
      const oldCall = this._calls && this.getCallByParticipantID(call.participantID);
      call.actionSelect = oldCall && !call.disconnected
        ? oldCall.actionSelect
        : 0;
      call.handRaisingChecked = oldCall && !call.disconnected && call.handRaisedIndexDisplay
        ? oldCall.handRaisingChecked
        : 0;

      call.activity = this._getActivity(call);

      if (!call.disconnected) {
        this._callCountCurrent++;
      }

      for (let prop in this._setCallProps) {
        if (this._setCallProp_test[prop]) {
          // special test function defined for this prop
          if (this._setCallProp_test[prop](call[prop]))
            this._setCallProps[prop] = true;
        } else {
          // default test function - check truthiness
          if (call[prop])
            this._setCallProps[prop] = true;
        }
      }
      for (let prop in this._totals)
        this._totals[prop] += parseInt(call[prop], 10) || 0;
    });

    this._calls = calls;

    this.emit('update');
  }

  setSubConfID(subConfID) {
    if (this._subConfID === subConfID) return;

    this._subConfID = subConfID;
    this._lastDuration = null;
    this._onUpdate();
  }

  start(loginData) {
    const { identity, bridge } = loginData;

    this._identity = identity;

    this._setBridgeData(bridge);

    this._poller.scheduleNext();
    if (this.chatController)
      this.chatController.updateName(identity.name);

    this.broadcastController.start();
  }

  stop() {
    this._poller.stop();
    this._stopPollResponseTimer();
    this._initPollerState();
    this._prevRequestError = false;

    this._bridgeData = null;
    this._bridgeConfSettings = null;
    this._identity = null;
    this._subConfID = null;
    this.setDataInactive();

    this._apiContext.abortAll();

    if (this.chatController) {
      // create one-off ApiContext with current authToken before
      // authToken is cleared from defaultContext and ClientStorage
      const ctx = new ApiContext('stopDataSession', this._config.apiConfig);
      ctx.setAuthParams({
        authToken: {
          token: this._apiContext.getAuthToken(),
        },
      });

      Api.get('LCM', 'stopDataSession', {}, {}, ctx)
        .catch(err => {
          // swallow errors
        });
    }

    this.broadcastController.stop();
  }

  retry() {
    this._poller.retry();
  }

  pollIfInactive() {
    // do nothing when not logged in
    if (!this._identity)
      return;

    if (!this._tcsState) {
      this._poller.scheduleNext();
    }
  }

  fetchBridgeData() {
    const params = {
      dateFormat: 'longEnglishDate'
    };

    return Api.get('Bridge', 'getBridges', params, { cancelID: 'BridgeObjectFetch' })
      .then(res => {
        const bridge = res.bridge && res.bridge[0];
        if (!bridge)
          throw new AuthError('no bridge result');

        this._setBridgeData(bridge);

        return bridge;
      });
  }

  createCallObserver(participantID, { onUpdate = null, onSubConfChange = null, onDisconnected = null, onGone = null }) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    let observerList = this._callObservers.get(participantID);
    if (!observerList) {
      observerList = [];
      this._callObservers.set(participantID, observerList);
    }

    const observer = new CallObserver(
      () => this._destroyCallObserver(observer),
      call,
      {
        onUpdate,
        onSubConfChange,
        onDisconnected,
        onGone
      }
    );
    observerList.push(observer);

    return observer;
  }

  _destroyCallObserver(observer) {
    const observerList = this._callObservers.get(observer.participantID);
    if (!observerList) {
      return;
    }

    const idx = observerList.indexOf(observer);
    if (idx === -1) {
      return;
    }
    observerList.splice(idx, 1);

    if (!observerList.length) {
      this._callObservers.delete(observer.participantID);
    }
  }

  get adminID() {
    if (this._identity)
      return this._identity.adminID;

    return null;
  }

  get features() {
    return {
      subConfs: !!this._config.enableSubconferences,
      showCallerID: !!this._scope.showCallerID,
      recording: !!(!this._config.disableRecording && this._bridgeData && this._bridgeData.recordingAllowedFlag),

      userIDEnabled: !!(this._bridgeData && this._bridgeData.validateUserID !== 0),
      userIDRequired: !!(this._bridgeData && [ 1, 3 ].includes(this._bridgeData.validateUserID)),
    };
  }

  get bridgeInfo() {
    if (this._bridgeData) {
      const {
        conferenceIDFormatted,
        name,
        pin,
        tollNumberFormatted,
        tollFreeNumberFormatted,
      } = this._bridgeData;

      return {
        conferenceIDFormatted,
        name,
        pin,
        tollNumberFormatted,
        tollFreeNumberFormatted,
      };
    } else {
      return {
        conferenceIDFormatted: null,
        name: null,
        pin: null,
        tollNumberFormatted: null,
        tollFreeNumberFormatted: null,
      };
    }
  }

  get state() {
    return this._state;
  }

  get isConfActive() {
    return this._state === 'active';
  }

  get retryWaitSecs() {
    return this._poller.retryWaitSecs;
  }

  get mainConfCounts() {
    return {
      callCount: this._callCountMainConf,
      handRaisedCount: this._callCountMainConfHands,
    };
  }

  get subConfID() {
    return this._subConfID;
  }

  get subConfs() {
    return this._subConfs;
  }

  get isSubConfActive() {
    return this._subConfID !== null;
  }

  get confPending() {
    const confState = this._confState;
    if (!confState) {
      return false;
    }

    return confState.waitingForHost;
  }

  get callCountDisplay() {
    if (!this.isConfActive) return null;

    let ret = this._callCountTotalActive;
    if (this._callCountCurrent !== this._callCountTotalActive) {
      ret = `${this._callCountCurrent} / ${ret}`;
    }

    return ret;
  }

  get callCountTotalDisconnected() {
    if (!this.isConfActive) return null;

    return this._callCountTotalDisconnected;
  }

  get nameTranscribeCount() {
    if (!this._confState) {
      return 0;
    }

    return this._confState.nameTranscribeCount;
  }

  get startedDateDisplay() {
    if (!this._confState) {
      return null;
    }

    return this._confState.startedDateDisplay;
  }

  get duration() {
    const confState = this._confState;
    if (!confState) {
      return null;
    }

    if (confState.duration === null) {
      return null;
    }

    const duration = confState.duration + this._localTimeOffset;

    if (this._lastDuration !== null && this._lastDuration > duration)
      return this._lastDuration;

    this._lastDuration = duration;
    return duration;
  }

  get comment() {
    if (!this._confState) {
      return null;
    }

    return this._confState.dataPerm;
  }

  get settings() {
    return {
      ...this._settings,
    };
  }

  get confMode() {
    if (!this.isConfActive) return null;
    return this._settings.confMode;
  }

  get locked() {
    if (!this.isConfActive) return null;
    return this._settings.locked;
  }

  get recordCalls() {
    if (!this.isConfActive) return null;
    return this._settings.recordCalls;
  }

  setConfSetting(name, value) {
    let command;
    switch (name) {
    case 'confMode':
      command = 'setMode';
      break;
    case 'locked':
      command = 'setLocked';
      break;
    case 'entryChimes':
      command = 'setEntryMode';
      break;
    case 'exitChimes':
      command = 'setExitMode';
      break;
    case 'recordCalls':
      command = 'setRecording';
      break;

    default:
      throw new Error(`unknown setting ${name}`);
    }

    ConfCommand.send(command, value);
  }

  // types:
  // monitor (listen only),
  // whisper (speak to caller without interrupting conference audio or changing the conference mode for the line),
  // privateCall (speak to the caller with no conference audio but without changing the conference mode for the line or moving them to a sub-conference)
  connectToCall(participantID, type) {
    if (!this._clientCallID) {
      return;
    }

    const dstCall = this.getCallByParticipantID(participantID);
    if (!dstCall) {
      return;
    }

    const params = {
      srcCallID: this._clientCallID,
      dstCallID: dstCall.callID,

      srcRemoveFromConf: true,
      srcSend: type === 'whisper' || type === 'privateCall',
      srcRecv: true,
      dstRemoveFromConf: type === 'privateCall',
      dstSend: true,
      dstRecv: type === 'whisper' || type === 'privateCall',
    };

    return Api.get('LCM', 'connectCalls', params);
  }

  connectedCallDisconnect() {
    if (!this._clientCallID) {
      return;
    }

    const params = {
      srcCallID: this._clientCallID,
      dstCallID: null,
    };

    Api.get('LCM', 'connectCalls', params)
      .catch(err => {
      });
  }

  get connectedCall() {
    if (!this._tcsState) {
      return null;
    }

    return this._tcsState.connectedCall;
  }

  get connectedCallCallerID() {
    const { connectedCall } = this;

    if (!connectedCall) {
      return null;
    }

    return connectedCall.callerID;
  }

  get writable() {
    return !!this._scope.write;
  }

  get canManageHands() {
    const { write, raisedHandMove } = this._scope;
    return !!(write || raisedHandMove);
  }

  get makeCallAllowed() {
    return !!(!this._config.disableMakeCall && this._bridgeData && this._bridgeData.makeCallAllowedFlag);
  }

  get broadcastAllowed() {
    return !!(this._config.enableBroadcast && this._bridgeData && this._bridgeData.enableBroadcast);
  }

  get identityName() {
    if (!this._identity)
      return null;

    return this._identity.name;
  }

  get operatorFlag() {
    return !!(this._identity && this._identity.operatorFlag);
  }

  get isClientCallConnected() {
    return !!this._clientCallID;
  }

  get webCallAllowed() {
    return !!(this._scope.webCall && !(this.operatorFlag && !this._enableOperatorWebCall));
  }

  get setCallProps() {
    return  {
      ...this._setCallProps,
    };
  }

  get totals() {
    return {
      ...this._totals,
    };
  }

  getCalls(filterFunc  = null, compareFunc = null) {
    // shallow clone
    let items = this._calls.slice();

    if (filterFunc)
      items = items.filter(item => filterFunc(item));
    if (compareFunc)
      items = items.sort((a, b) => compareFunc(a, b));

    return items;
  }

  get hands() {
    const { writable, handRaisingSelected } = this;
    return {
      selected: handRaisingSelected,
      isSelectAllowed: !!(writable && !this.isSubConfActive),
      showSelectionActions: !!(writable && handRaisingSelected),
    };
  }

  get handRaisingSelected() {
    return this._subConfID === null
      ? this._handRaisingSelected
      : null;
  }

  get handRaisingPrevSelectedCallID() {
    return this._subConfID === null
      ? this._handRaisingPrevSelectedCallID
      : null;
  }

  get handRaisingLowerAllAllowed() {
    return this._config.handRaisingLowerAllAllowed;
  }

  getSelectedCalls(selectProp) {
    return this._calls.filter(call => call[selectProp]);
  }

  getCallByParticipantID(participantID) {
    return this._calls.find(call => call.participantID === participantID);
  }

  _getCallByParticipantIDOrFail(participantID) {
    const call = this.getCallByParticipantID(participantID);
    if (!call) {
      throw new Error(`participantID ${participantID} not found`);
    }

    return call;
  }

  get namePlayingParticipantID() {
    return this._namePlayingParticipantID;
  }

  set namePlayingParticipantID(participantID) {
    this._namePlayingParticipantID = participantID;
    this.emit('update');
  }

  get timezone() {
    return this._bridgeData && this._bridgeData.timezone || null;
  }

  get authInfo() {
    const {
      accountID = null,
      accountName = null,
      bridgeID = null,
      conferenceID = null,
      pin = null,
    } = this._bridgeData || {};

    return {
      partnerID: this._config.partnerID,
      accountID,
      accountName,
      bridgeID,
      conferenceID,
      pin,
    };
  }

  get lastLoginIP() {
    return this._bridgeData && this._bridgeData.lastLoginIP || null;
  }

  get lastLoginDateLocal() {
    return this._bridgeData && this._bridgeData.lastLoginDateLocal || null;
  }

  get logoPath() {
    return this._bridgeData && this._bridgeData.logoPath || null;
  }

  get notesFields() {
    return this._notesFields;
  }

  get notesFieldsPermissions() {
    const perms = {};

    if (this._notesFields) {
      for (let name in this._notesFields) {
        const opts = this._notesFields[name];

        if (this.operatorFlag) {
          perms[name] = {
            read: true,
            write: !!opts.allowEdit,
          };
        } else {
          perms[name] = {
            read: !!opts.hostReadable,
            write: !!(opts.hostReadable && opts.hostWritable),
          };
        }
      }
    }

    return perms;
  }

  disconnectCall(participantID) {
    return Promise.resolve()
      .then(() => {
        const call = this.getCallByParticipantID(participantID);
        if (!call || call.disconnected)
          throw new NotFoundError();

        return call.callID;
      })
      .then(callID => this._sendCallCommand('disconnect', callID, 1));
  }

  getSelectState(selectProp, filterFunc = null) {
    let empty = true;
    let allSelected = true;
    let someSelected = false;
    let count = 0;

    this._calls.forEach(call => {
      if (call.disconnected)
        return;

      if (filterFunc && !filterFunc(call))
        return;

      empty = false;

      if (!call[selectProp]) {
        allSelected = false;
      } else {
        someSelected = true;
        count++;
      }
    });

    allSelected = allSelected && !empty;

    return {
      allSelected,
      someSelected,
      count,
    };
  }

  select(selectProp, type, filterFunc = null) {
    this._calls.forEach(call => {
      if (filterFunc && !filterFunc(call))
        return;

      let selected = false;

      if (!call.disconnected) {
        switch (type) {
        case 'all':
          selected = true;
          break;

        case 'starred':
          selected = call.starred;
          break;

        case 'hosts':
          selected = call.host;
          break;

        case 'participants':
          selected = !call.host;
          break;

        case 'raisedHands':
          selected = call.handRaisedIndexDisplay !== null;
          break;

        case 'onHold':
          selected = call.hold;
          break;
        }
      }

      call[selectProp] = selected ? 1 : 0;
    });

    this.emit('update');
  }

  toggleCallProperty(participantID, property) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    const value = call[property] ? 0 : 1;

    call[property] = value;
    this.emit('update');

    const { callID } = call;
    switch (property) {
    case 'starred':
      CallCommand.setStar(callID, value);
      break;
    case 'host':
      CallCommand.setHost(callID, value);
      break;
    case 'muted':
      CallCommand.setMute(callID, value);
      break;
    }
  }

  setSelectedMute(selectProp, mute) {
    const selected = this.getSelectedCalls(selectProp);
    const val = mute ? 1 : 0;
    if (selected.length) {
      CallCommand.setMute(selected.map(call => call.callID), val);
    } else {
      ConfCommand.setMute(this._subConfID, val);
    }
  }

  unstarAll() {
    const calls = this._calls.filter(call => !call.disconnected && call.starred);
    CallCommand.setStar(calls.map(call => call.callID), 0);
  }

  endConference() {
    const subConfID = this._subConfID;

    if (subConfID === null) {
      ConfCommand.disconnect();
    } else {
      ConfCommand.mergeSubConf(subConfID);

      this.emit('changeSubConfID', null);
    }
  }

  handSelect(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.raisedHandSelect(call.callID);
  }

  handRaise(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.raisedHandRaise(call.callID);
  }

  handLower(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.raisedHandLower(call.callID);
  }

  handLowerSelected(selectProp) {
    const selected = this.getSelectedCalls(selectProp);
    CallCommand.raisedHandLower(selected.map(call => call.callID));
  }

  handLowerAll() {
    ConfCommand.lowerAllHands(this._subConfID);
  }

  handReselectLast() {
    const callID = this.handRaisingPrevSelectedCallID;
    if (callID) {
      CallCommand.raisedHandSelect(callID);
    }
  }

  handMove(participantID, direction, targetParticipantID = null) {
    const call = this._getCallByParticipantIDOrFail(participantID);

    if ((direction === 'before' || direction === 'after') && !targetParticipantID)
      throw new Error('targetParticipantID required');

    let handRaisedIndex = null;
    if (targetParticipantID) {
      const targetCall = this._getCallByParticipantIDOrFail(targetParticipantID);

      handRaisedIndex = targetCall.handRaisedIndex;
      if (handRaisedIndex === null || handRaisedIndex === undefined)
        throw new Error('targetParticipantID invalid');
    }

    CallCommand.raisedHandMove(call.callID, direction, handRaisedIndex);
  }

  setInGain(participantID, inGain) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    this._sendCallCommand('setInGain', call.callID, inGain);
  }

  handMoveMultiple(calls, direction) {
    let flip = false;
    switch (direction) {
    case 'top':
      flip = true;
      break;
    case 'bottom':
      break;
    default:
      throw new Error('invalid direction');
    }

    calls.sort((a, b) => {
      if (a.handRaisedIndexDisplay > b.handRaisedIndexDisplay)
        return flip ? -1 : 1;

      return flip ? 1 : -1;
    });

    const promises = calls.reduce((promise, call) => {
      return promise.then(() => this._sendCallCommand('raisedHandMove', call.callID, direction));
    }, Promise.resolve());

    promises.catch(err => {
    });
  }

  talkToOperator(target) {
    const opCall = this._allCalls.find(call => call.isClientCall);
    if (!opCall) return;

    let subConfID = 'Op';
    if (this._identity.operatorID)
      subConfID = `${subConfID}_${this._identity.operatorID}`;

    const params = {
      setMute: 0,
    };

    CallCommand.setSubConf(target, subConfID, params);
    CallCommand.setSubConf(opCall.callID, subConfID, params);

    this.emit('changeSubConfID', subConfID);
  }

  sendToHelpQueue(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.sendToHelpQueue(call.callID);
  }

  removeFromHelpQueue(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.removeFromHelpQueue(call.callID);
  }

  sendToEnterQueue(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.sendToEnterQueue(call.callID);
  }

  sendToSubConf(targets, subConfID, options = {}) {
    const {
      muted = false,
      muteLocked = false,
      hold = false,
    } = options;

    const params = {
      muted,
      muteLocked,
      hold,
    };

    CallCommand.setSubConf(targets, subConfID, params);
  }

  saveCallerInfo(callID, data) {
    const { name, notesType, notes, notesObject } = data;

    return Api.get('LCM', 'setCallerInfo', {
      callID,
      name,
      notesType,
      notes: notesType === 'string'
        ? notes
        : notesObject,
    });
  }

  getNameTranscribe() {
    const { timezone } = this;
    const params = {
      ...(timezone && { timezone }),
    };

    return Api.get('LCM', 'getNameTranscribe', params)
      .then(res => {
        const call = res.nameTranscribeCall;
        this._setCallFields(call);
        return call;
      });
  }

  putNameTranscribe(callID) {
    return Api.get('LCM', 'putNameTranscribe', { callID });
  }

  _sendCallCommand(command, callID, value, params) {
    const reqParams = {
      command,
      callID,
      value,
    };

    if (params)
      reqParams.params = params;

    return Api.get('LCM', 'changeConferenceCall', reqParams);
  }

  onWebCallConfUpdate(event) {
    this._webCallLastCallID = event && event.callID;
    this.pollIfInactive();
  }

  _getActivity(call) {
    const { disconnected, hold, lastTalked } = call;
    if (disconnected)
      return ConfController.ACTIVITY_DISCONNECTED;

    if (hold)
      return ConfController.ACTIVITY_HOLD;

    if (this._confState && this._confState.status === 'pending')
      return ConfController.ACTIVITY_PENDING;

    if (lastTalked === null || lastTalked > 5000)
      return ConfController.ACTIVITY_5;

    if (lastTalked > 2000)
      return ConfController.ACTIVITY_2;

    return ConfController.ACTIVITY_0;
  }

  _tick() {
    if (this._apiContext.checkLoggedOut()) return;

    if (!this.isConfActive) return;

    this._localTimeOffset++;

    const d = new Date();
    const timeDiff = d.getTime() - this._lastUpdateTime;

    this._calls.forEach(call => {
      let { lastTalked } = call;

      if (lastTalked === null || lastTalked === undefined) {
        return;
      }

      if (lastTalked / 1000 < 2) {
        return;
      }

      call.lastTalked += timeDiff;
      call.activity = this._getActivity(call);
    });

    this.emit('update');
  }

  _setCallFields(call) {
    const { operatorFlag } = this;

    var pos = call.fromAddress.lastIndexOf(':');
    if (pos != -1)
      call.fromAddress =  call.fromAddress.substr(0, pos);

    if (!call.name)
      call.name = s.lblUnknown;

    call.number          = call.fromNumber;
    call.numberFormatted = call.fromNumberFormatted;

    if (call.number === null)
      call.numberFormatted = s.lblUnknown;

    call.callerIDDisplay = call.callerID;
    if (call.accessMethod !== C.ACCESS_METHOD_PSTN) {
      call.callerIDDisplay = call.accessMethodDisplay + ' - ' + call.callerIDDisplay;
    }

    if (call.hold === undefined)
      call.hold = 0;

    call.durationMinutes = Math.floor(call.duration / 60);

    // munge notesType/notes/notesObject/notesLabel
    call.notesLabel = '';

    let notes = null;
    let notesObject = null;

    if (this._notesFields) {
      call.notesType = 'object';
      if (call.notes && typeof call.notes === 'object') {
        notesObject = call.notes;
        const showFields = [];

        for (let field in this._notesFields) {
          const opts = this._notesFields[field];

          if (notesObject[field] && ((operatorFlag && opts.operatorShowInHandQueue) || (!operatorFlag && opts.hostShowInHandQueue)))
            showFields.push(field);
        }

        if (showFields.length) {
          call.notesLabel +=
            ' [' + showFields.map(_ => notesObject[_]).join(' ') + ']';
        }
      }
    } else {
      call.notesType = 'string';
      if (call.notes && typeof call.notes === 'string')
        notes = call.notes;
    }
    call.notes = notes;
    call.notesObject = notesObject;

    call.callerNameDisplay = call.name + call.notesLabel;
    call.callerNameCallerID = `${call.callerNameDisplay} ${call.callerIDDisplay}`;

    call.starred = false;
    if (call.callData && call.callData.starred == 1)
      call.starred = true;

    if (call.userID === undefined)
      call.userID = null;

    if (call.lastTalked === undefined)
      call.lastTalked = null;

    if (call.disconnected) {
      call.starred      = 0;
      call.muted        = 0;
      call.host         = 0;
    }

    if (call.muted === undefined)
      call.muted = 0;

    if (call.nameRecorded === undefined)
      call.nameRecorded = 0;

    if (call.nameRecorded && !call.nameTranscribePosition)
      call.nameTranscribePosition = Infinity;

    call.recordings = [];
    const { infoRecordingState = {} } = call;
    for (const { tag, label } of this._infoRecordingCurrent) {
      const state = tag in infoRecordingState
        ? infoRecordingState[tag]
        : C.IR_STATE_SKIPPED;
      call.recordings.push({
        tag,
        label,
        state,
      });
    }

    call.isClientCall =
      call.callID === this._webCallLastCallID ||
      (call.operator && call.operator === this._identity.operatorID);

    const { write } = this._scope;
    const { canManageHands } = this;
    call.isActionAllowed = !(call.disconnected || !write);
    call.isHandRaisingActionAllowed = !(call.disconnected || !canManageHands);

    call.isBroadcast = !!(
      !call.disconnected &&
      call.broadcast &&
      call.dataPerm &&
      call.dataPerm.broadcast &&
      call.stream &&
      call.stream.state
    );

    if (operatorFlag) {
      call.operatorHelpRequested = !!call.operatorHelpRequested;
    } else {
      call.operatorHelpRequested = false;
    }

    // setup custom columns
    this._customColumns.forEach(def => {
      let val = '';

      switch (def.type) {
      case 'temp':
        if (call.callData && def.name in call.callData)
          val = call.callData[def.name];

        break;

      case 'perm':
        if (call.dataPerm && def.name in call.dataPerm)
          val = call.dataPerm[def.name];

        break;

      case 'notes':
        if (call.notesObject && def.name in call.notesObject)
          val = call.notesObject[def.name];

        break;

      case 'callProperty':
        if (def.name in call)
          val = call[def.name];

        break;
      }

      call[def.keyName] = val;
    });
  }
}

function createConfState(data) {
  const {
    status,
    startedDateDisplay = null,
    duration = null,

    dataPerm = null,
    nameTranscribeCount,
  } = data;

  return {
    status,
    startedDateDisplay,
    duration,

    dataPerm,
    nameTranscribeCount,
    waitingForHost:
      status === 'pending' &&
      data.confStart !== 'instant' &&
      data.confStart !== 'secondCaller',
  };
}

function getConfSettings(data) {
  const {
    confMode,
    entryChimes,
    exitChimes,
    locked,
    recordCalls,
  } = data;

  return {
    confMode,
    entryChimes,
    exitChimes,
    locked,
    recordCalls,
  };
}

function createIdleSubConf(name) {
  return {
    name,
    callCount: 0,
    handRaisedCount: 0,

    confState: {
      status: 'idle',
      startedDateDisplay: null,
      duration: null,

      nameTranscribeCount: 0,
      dataPerm: null,
      waitingForHost: false,
    },
  };
}

class CallObserver {
  constructor(
    destroyCallback,
    call,
    {
      onUpdate = null,
      onSubConfChange = null,
      onDisconnected = null,
      onGone = null,
    }
  ) {
    this._destroyCallback = destroyCallback;
    this._call = call;
    this._lastSubConfID = this._call.subConf;
    this._gone = false;

    this._onUpdate = onUpdate;
    this._onSubConfChange = onSubConfChange;
    this._onDisconnected = onDisconnected;
    this._onGone = onGone;
  }

  update(call) {
    if (call) {
      this._call = call;
    } else {
      this._gone = true;
    }

    // NB: The order of queueMicrotask() calls here is important and
    // is from highest to lowest priority.
    // The rationale for this particular order is that things
    // listening for onGone/onDisconnected/onSubConfChange typically
    // don't want any more updates and stop observing by calling
    // destroy() on the observer.  If a higher priority microtask
    // calls destroy() then the observer clears all ._on* handlers,
    // which ensures that onUpdate microtasks don't call the onUpdate
    // handler when it is no longer wanted.
    if (this._onGone && this._gone) {
      queueMicrotask(() => {
        if (this._onGone) {
          this._onGone(this);
        }
      });
    }
    if (this._onDisconnected && this.disconnected) {
      queueMicrotask(() => {
        if (this._onDisconnected) {
          this._onDisconnected(this);
        }
      });
    }
    if (this._onSubConfChange && !this.disconnected && this.subConfID !== this._lastSubConfID) {
      queueMicrotask(() => {
        if (this._onSubConfChange) {
          this._onSubConfChange(this);
        }
      });
    }
    if (this._onUpdate) {
      queueMicrotask(() => {
        if (this._onUpdate) {
          this._onUpdate(this);
        }
      });
    }

    this._lastSubConfID = this.subConfID;
  }

  destroy() {
    this._onUpdate = null;
    this._onSubConfChange = null;
    this._onDisconnected = null;
    this._onGone = null;
    this._destroyCallback();
  }

  get participantID() {
    return this._call.participantID;
  }

  get gone() {
    return this._gone;
  }

  get disconnected() {
    return !!(this._gone || this._call.disconnected);
  }

  get subConfID() {
    return this._call.subConf;
  }

  get recordings() {
    return this._call.recordings;
  }

  get inGain() {
    return this._call.inGain;
  }

  get outGain() {
    return this._call.outGain;
  }
}
